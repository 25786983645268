import { render, staticRenderFns } from "./badge-list.view.vue?vue&type=template&id=3926fae2&"
import script from "./badge-list.view.ts?vue&type=script&lang=ts&"
export * from "./badge-list.view.ts?vue&type=script&lang=ts&"
import style0 from "./badge-list.view.vue?vue&type=style&index=0&id=3926fae2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports