import { Component, Prop, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { BadgeFormModes, BadgeFormOptions, RegistrationType } from '@/modules/level-two/models/badges.model'

@Component({
  name: 'GtrBadgesBadgeFormModal'
})
export default class GtrBadgesBadgeFormModal extends GtrSuper {
  BadgeFormModes = BadgeFormModes

  @Prop({ required: true, type: Boolean, default: false })
  visible: boolean | undefined

  @Prop({ required: true, type: Array, default: [] })
  registrationTypes: RegistrationType[] | undefined

  @Prop({ required: true, type: Object, default: { mode: BadgeFormModes.CREATE, title: 'New Badge' } })
  options: BadgeFormOptions | undefined

  @Prop({ required: true, type: Array, default: [] })
  registration_type_counts: [] | undefined

  @Prop({ required: false, type: Object })
  event_stats: any | undefined

  data () {
    return {
      submitting: false,
      svg_file_name: null,
      badge: {
        name: null,
        svg: null,
        badge_properties: {
          description: null,
          size: null,
          user_name: null
        },
        registration_type: null,
        printer_properties: {},
        active: true
      }
    }
  }

  mounted () {
    this.$data.emptyBadge = JSON.parse(JSON.stringify(this.$data.badge)) // store default values
  }

  @Watch('options')
  initializeBadge () {
    if (this.options?.badge) {
      this.$data.badge = JSON.parse(JSON.stringify(this.options.badge)) // copy original badge data
      if (this.options.mode === BadgeFormModes.DUPLICATE) {
        delete this.$data.badge.uuid
        delete this.$data.badge.created_at
        delete this.$data.badge.updated_at
        delete this.$data.badge.deleted_at
      }
    }
    if (this.options?.size) {
      this.$data.badge.badge_properties.size = this.options.size
    }
    if (this.options?.active === 0 || this.options?.active === 1) {
      this.$data.badge.active = this.options.active
    }
  }

  // Available registration types are ones that are visible and not assigned to any badges or are assigned to the badge currently loaded in the form.
  get availableRegistrationTypes (): RegistrationType[] {
    const availableRegistrationTypes: RegistrationType[] = []

    if (Array.isArray(this.registrationTypes)) {
      for (let i = 0; i < this.registrationTypes.length; i++) {
        const regType = this.registrationTypes[i]
        if ((regType.visible && !regType.badge_uuid) || regType.badge_uuid === this.$data.badge.uuid) {
          availableRegistrationTypes.push(regType)
        }
      }
    }

    return availableRegistrationTypes
  }

  get registrationTypesUrl () {
    const routeData = this.$router.resolve({ name: 'level-two.modules.registration.options' })
    return routeData.href || ''
  }

  handleFileSelect (event: Event) {
    if (event) {
      const input = event.target as HTMLInputElement
      if (input?.files?.length === 1) {
        this.processFile(input.files[0])
      }
    }
  }

  handleFileDrop (event: DragEvent) {
    if (this.$data.badge.svg) {
      Container.get(Notification).error('You can only upload one file.')
    } else {
      if (event.dataTransfer?.files.length === 1) {
        const file = event.dataTransfer.files[0]
        this.processFile(file)
      } else {
        Container.get(Notification).error('Please upload one SVG file.')
      }
    }
  }

  processFile (file: File) {
    if (file !== null && file.type === 'image/svg+xml') {
      // Parse SVG file as text and store it in the badge data.
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.$data.badge.svg = reader.result
      }, false)
      if (file) {
        reader.readAsText(file)
      }

      this.$data.svg_file_name = file.name
    } else {
      Container.get(Notification).error('Please upload an SVG file.')
    }
  }

  async submit () {
    try {
      this.$data.submitting = true
      // check for attendees
      if (this.event_stats) {
        if (this.event_stats.statusBreakdown.length === 0 || this.event_stats.statusBreakdown.complete < 1) {
          Container.get(Notification).error('No participants exist.')
          return
        }
      }
      // get regTypeName from badge.registration_type
      const regTypeUuid = this.$data.badge.registration_type
      let regTypeName = ''
      if (this.registrationTypes) {
        for (let i = 0; i < this.registrationTypes.length; i++) {
          const regType = this.registrationTypes[i]
          if (regType.uuid === regTypeUuid) {
            regTypeName = regType.name
          }
        }
      }
      // compare selected regTypeName to registration_type_counts and check for reg type attendees
      if (this.registration_type_counts) {
        for (let i = 0; i < this.registration_type_counts.length; i++) {
          const regTypeObj: any = this.registration_type_counts[i]
          if (regTypeName === regTypeObj.name) {
            if (regTypeObj.total === 0) {
              Container.get(Notification).error('No participants of this registration type exist.')
              return
            }
          }
        }
      }
      // if checks pass
      this.$data.badge.badge_properties.user_name = this.$store.state.security.currentUser.name
      let message = ''
      if (this.$props.options.mode === BadgeFormModes.EDIT) {
        await this.$store.dispatch('badges/updateBadge', { event_uuid: this.$route.params.event_uuid, badge_uuid: this.$data.badge.uuid, data: this.$data.badge })
        message = 'Badge successfully updated.'
      } else {
        await this.$store.dispatch('badges/createBadge', { event_uuid: this.$route.params.event_uuid, data: this.$data.badge })
        message = 'Badge successfully created.'
      }
      Container.get(Notification).success(message)
      this.$emit('badgeSubmitted')
      this.$emit('close')
      this.clearForm()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.submitting = false
    }
  }

  handleUseSvgBuilder () {
    const size = this.$data.badge.badge_properties.size
    this.$emit('close')
    this.$emit('useSvgBuilderModal', size)
    this.clearForm()
  }

  handleClose () {
    this.clearForm()
    this.$emit('close')
  }

  private clearForm () {
    this.$data.badge = JSON.parse(JSON.stringify(this.$data.emptyBadge)) // restore default values
  }
}
