import { Component } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { BadgeFormModes, BadgeFormOptions, HubspotFormOptions } from '@/modules/level-two/models/badges.model'

type LabelSize = {
  name: string;
  path: string;
  template: string;
  description: string;
  avery_templates: string|null;
  printers: string[];
}
@Component({
  name: 'GtrBadgesSelectSize'
})
export default class GtrBadgesSelectSize extends GtrSuper {
  BadgeFormModes = BadgeFormModes

  data () {
    return {
      printers: [
        'Epson CW-C4000',
        'Zebra ZD-500, 620, 621 (203DPI)',
        'Zebra Z10L',
        'Desktop Printer'
      ],
      selectedPrinters: [] as string[],
      labels: [
        {
          name: '3.46" x 5.51"',
          path: require('@/assets/img/badges/size-3.46x5.51.svg'),
          template: require('@/assets/img/badges/template-3.46x5.51.svg'),
          description: 'Single-sided badges on paper that is more durable than standard (thin) paper stock.',
          avery_templates: null,
          printers: ['Zebra Z10L']
        },
        {
          name: '3-3/8" x 2-1/3"',
          path: require('@/assets/img/badges/size-3.375x2.333.svg'),
          template: '',
          description: 'Single-sided badges that supports Avery template:',
          avery_templates: '5152, 5395, 8395',
          printers: ['Desktop Printer']
        },
        {
          name: '3-1/2" x 2-1/4"',
          path: require('@/assets/img/badges/size-3.5x2.25.svg'),
          template: '',
          description: 'Single-sided badges that supports Avery template:',
          avery_templates: '5390',
          printers: ['Desktop Printer']
        },
        {
          name: '4" x 2"',
          path: require('@/assets/img/badges/size-4x2.svg'),
          template: '',
          description: 'Single-sided badges that supports Avery template:',
          avery_templates: '5163, 5263, 5523, 5923, 5963, 8463',
          printers: ['Desktop Printer']
        },
        {
          name: '4" x 3"',
          path: require('@/assets/img/badges/size-4x3.svg'),
          template: require('@/assets/img/badges/template-4x3.svg'),
          description: 'Single-sided badges on paper that is more durable than standard (thin) paper stock. Also supports Avery template:',
          avery_templates: '5384, 5392, 74459, 74536, 74640, 74541',
          printers: ['Zebra ZD-500, 620, 621 (203DPI)', 'Desktop Printer']
        },
        {
          name: '4" x 5"',
          path: require('@/assets/img/badges/size-4x5.svg'),
          template: require('@/assets/img/badges/template-4x5.svg'),
          description: 'Single-sided badges on paper that is more durable than standard (thin) paper stock.',
          avery_templates: null,
          printers: ['Epson C-3500']
        },
        {
          name: '4" x 6"',
          path: require('@/assets/img/badges/size-4x6.svg'),
          template: require('@/assets/img/badges/template-4x6.svg'),
          description: 'Double-sided badges on paper that is more durable than standard (thin) paper stock.',
          avery_templates: null,
          printers: ['Epson C-3500', 'Epson CW-C4000', 'Zebra ZD-500, 620, 621 (203DPI)']
        },
        {
          name: '4" x 12"',
          path: require('@/assets/img/badges/size-4x12.svg'),
          template: require('@/assets/img/badges/template-4x12.svg'),
          description: 'Double-sided badges on paper that is more durable than standard (thin) paper stock.',
          avery_templates: null,
          printers: ['Epson C-3500', 'Epson CW-C4000', 'Zebra ZD-500, 620, 621 (203DPI)']
        }
      ],
      customBadgesFormModal: {
        formId: '5937b31f-46f0-4fbd-88d0-ddcdf101dd79'
      },
      designHelpFormModal: {
        formId: '411a3db5-aaf3-413a-82fa-05ce9c2a725d'
      }
    }
  }

  mounted () {
    this.$data.selectedPrinters = this.$data.printers.slice(0)
  }

  get visibleLabels (): LabelSize[] {
    return this.$data.labels.filter(label => {
      return label.printers.some(printer => {
        return this.$data.selectedPrinters.includes(printer)
      })
    })
  }

  openBadgeFormModal (labelSize: LabelSize) {
    const options: BadgeFormOptions = {
      mode: BadgeFormModes.CREATE,
      title: `New ${labelSize.name} Badge`,
      size: labelSize.name,
      template: labelSize.template
    }
    this.$emit('openBadgeFormModal', options)
  }

  openHubspotFormModal (modal: string) {
    const options: HubspotFormOptions = {
      formId: this.$data[modal].formId,
      title: this.$data[modal].title || ''
    }
    this.$emit('openHubspotFormModal', options)
  }
}
