export const BadgeFormModes = {
  CREATE: 'CREATE',
  DUPLICATE: 'DUPLICATE',
  EDIT: 'EDIT'
} as const

export type BadgeFormMode = typeof BadgeFormModes[keyof typeof BadgeFormModes]

export type BadgeFormOptions = {
  mode: BadgeFormMode;
  title: string;
  size?: string;
  template?: string;
  badge?: object;
  active?: 0 | 1;
}

export type HubspotFormOptions = {
  formId: string;
  title?: string;
}

export type RegistrationType = {
  name: string;
  uuid: string;
  visible: 0 | 1;
  [index: string]: any;
}

export type BadgesSetting = {
  uuid: string;
  key: string;
  value: string | string[] | number | File | null;
  label: string;
  json_extra_data: any[];
  [index: string]: any;
}
