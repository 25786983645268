import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { HubspotFormOptions } from '@/modules/level-two/models/badges.model'

@Component({
  name: 'HubspotFormModal'
})
export default class HubspotFormModal extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  visible: boolean | undefined

  @Prop({ required: true, type: Object })
  options: HubspotFormOptions | undefined

  data () {
    return {}
  }

  mounted () {
    if (!document.getElementById('hubspotFormScript')) {
      const formScript = document.createElement('script')
      formScript.setAttribute('src', '//js.hsforms.net/forms/v2.js')
      formScript.setAttribute('id', 'hubspotFormScript')
      document.head.appendChild(formScript)
    }
  }

  @Watch('options')
  initializeForm () {
    if (this.visible) {
      setTimeout(() => {
        const formElement = this.$refs.hubspotForm as HTMLElement
        const formScript = document.createElement('script')

        formScript.innerHTML = `hbspt.forms.create({ region: 'na1', portalId: '8313159', formId: '${this.options?.formId}' })`
        formElement.appendChild(formScript)
      }, 300)
    }
  }

  closeModal () {
    const modalElement = this.$refs.hubspotForm as HTMLElement
    modalElement.innerHTML = ''
    this.$emit('close')
  }
}
